<template>
  <v-menu
    content-class="menu-arrow"
    transition="slide-x-transition"
    :close-on-content-click="false"
    bottom
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        class="button-primary"
        elevation="0"
        width="40px !important"
        height="40px !important"
      >
        <v-icon
          color="#000000"
          size="18px"
        >
          mdi-tune
        </v-icon>
      </v-btn>
    </template>

    <div class="content-menu">
      <div class="display-flex content-wrap">
        <div class="width-four-div content-1">
          <p class="text-title mon-bold">{{ texts.article.filter.textLabelCalification }}</p>
          <div
            v-for="(item, index) in starsItem"
            :key="index"
            class="display-flex align-items-center"
          >
            <v-checkbox
              v-model="ratingNumber"
              :value="item.value"
            />
            <v-rating
              :value="item.value"
              length="5"
              color="#FFC92A"
              background-color="#C6C6C8"
              dense
              size="14px"
              readonly
            ></v-rating>
          </div>
        </div>
        <div class="width-four-div content-2">
          <p class="text-title mon-bold">{{ texts.article.filter.textLabelAlphabetically }}</p>

          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="textAsc"
              @change="changeTextAsc"
            />
            <p class="text-values mon-regular">A-Z</p>
          </div>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="textDesc"
              @change="changeTextDesc"
            />
            <p class="text-values mon-regular">Z-A</p>
          </div>
        </div>
        <div class="width-four-div content-3">
          <p class="text-title mon-bold">{{ texts.article.filter.textLabelPrice }}</p>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="priceAsc"
              @change="changePriceAsc"
            />
            <p class="text-values mon-regular">{{ texts.article.filter.textLabelMinMax }}</p>
          </div>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="priceDesc"
              @change="changePriceDesc"
            />
            <p class="text-values mon-regular">{{ texts.article.filter.textLabelMaxMin }}</p>
          </div>
        </div>
        <!-- <div class="width-four-div content-4">
          <p class="text-title mon-bold">{{ texts.article.filter.textLabelItemStatus }}</p>
          <div class="display-flex align-items-center">
            <v-checkbox v-model="checkbox" />
            <p class="text-values mon-regular">{{ texts.article.filter.textLabelDisponible }}</p>
          </div>
          <div class="display-flex align-items-center">
            <v-checkbox v-model="checkbox" />
            <p class="text-values mon-regular">{{ texts.article.filter.textLabelSlow }}</p>
          </div>
        </div> -->
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "ArticleFilterLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      ratingNumber: [],
      textDesc: null,
      textAsc: null,
      priceDesc: null,
      priceAsc: null,
      resultTexts: "",
      resultPrice: "",
      starsItem: [
        { value: 5 },
        { value: 4 },
        { value: 3 },
        { value: 2 },
        { value: 1 },
      ],
    };
  },
  beforeMount() {},
  methods: {
    changeTextAsc: function () {
      this.textDesc = "";

      if (this.textAsc == true) {
        this.resultTexts = "asc";
      } else {
        this.resultTexts = "";
      }
    },
    changeTextDesc: function () {
      this.textAsc = "";

      if (this.textDesc == true) {
        this.resultTexts = "desc";
      } else {
        this.resultTexts = "";
      }
    },
    changePriceAsc: function () {
      this.priceDesc = "";

      if (this.priceAsc == true) {
        this.resultPrice = "asc";
      } else {
        this.resultPrice = "";
      }
    },
    changePriceDesc: function () {
      this.priceAsc = "";

      if (this.priceDesc == true) {
        this.resultPrice = "desc";
      } else {
        this.resultPrice = "";
      }
    },
  },
  computed: {},
  watch: {
    ratingNumber: function () {
      this.$store.commit("setSortArticleRating", this.ratingNumber);
    },
    resultTexts: function () {
      this.$store.commit("setSortArticleAlpha", this.resultTexts);
    },
    resultPrice: function () {
      this.$store.commit("setSortArticlePrice", this.resultPrice);
    },
  },
};
</script>

<style>
.width-four-div .v-icon.v-icon {
  font-size: 14px;
}

.width-four-div .v-input--selection-controls__ripple {
  height: 20px;
  width: 20px;
  left: -5px;
  top: calc(50% - 18px);
}

.width-four-div .v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px;
}

.width-four-div .v-input--selection-controls {
  margin-top: 0px;
}

.width-four-div .v-messages {
  display: none;
}

.width-four-div .v-input__slot {
  margin-bottom: 0px;
}

.width-four-div .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>

<style scoped>
.text-values {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -3px;
}

.text-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.width-four-div {
  width: 33.33%;
}

.content-menu {
  width: 670px;
  background-color: #ffffff;
  padding: 20px;
}

.menu-arrow {
  margin-top: 50px;
  contain: initial;
  overflow: visible;
}

/* .menu-arrow::before {
  position: absolute;
  content: "";
  top: 9px;
  right: 155px;
  transform: translateY(-170%);
  width: 10px;
  height: 13px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 13px solid #fff;
} */

.button-filter {
  width: 40px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
}

@media (max-width: 960px) {
  .content-menu {
    width: 470px;
  }

  .content-3,
  .content-4 {
    margin-top: 10px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 50%;
  }

  .content-wrap {
    flex-wrap: wrap;
  }

  .button-filter {
    margin-right: 0px !important;
  }
}

@media (max-width: 600px) {
  .content-menu {
    width: 200px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 100%;
    margin-top: 10px;
  }

  .content-wrap {
    flex-wrap: wrap;
  }
}
</style>